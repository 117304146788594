const swiper4 = new Swiper('.testimonials__carousel', {
  wrapperClass: 'testimonials__ribbon',
  slideClass: 'testimonials__single',
  direction: 'horizontal',
  loop: true,
  speed: 1000,
  slidesPerView: 1,
  spaceBetween: 25,
  // effect: 'fade',
  slideActiveClass: 'testimonials__single--active',
  autoplay: {
    delay: 6000,
  },
  pagination: {
    el: '.testimonials__bullets',
    type: 'bullets',
    bulletElement: 'div',
    bulletClass: 'testimonials__bullet',
    bulletActiveClass: 'testimonials__bullet--active',
    clickable: true,
  },
});